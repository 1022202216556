import React from 'react';
import { Modal } from '@redq/reuse-modal';
import { SEO } from 'components/seo';
import Authentication from 'containers/Authentication/Authentication';
import SiteFooter from 'components/SiteFooter/SiteFooter';

const LoginPage: React.FC = () => {

  return (
    <Modal>
      <SEO
        title='Login | Devo'
        description='Login to Devo and get you what your  favourite grocery items, when you need it.'
        canonical='/login'
      />
      <Authentication signIn />
      <SiteFooter />
    </Modal>
  );
}

export default LoginPage;